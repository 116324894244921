// extracted by mini-css-extract-plugin
export var alignLeft = "J_qF d_fn d_bF d_dt";
export var alignCenter = "J_bN d_fp d_bC d_dv";
export var alignRight = "J_qG d_fq d_bG d_dw";
export var testimonialsContainer = "J_wf d_dV";
export var testimonialsContainerFull = "J_wg d_dS";
export var testimonialsMainHeader = "J_jt d_jt d_v d_cv";
export var testimonialsComponentParagraph = "J_jw d_jw";
export var testimonialsComponentSmall = "J_jv d_jv";
export var testimonialsComponentsWrapper = "J_jq d_jq d_x d_v d_ct d_b2 d_b4";
export var testimonialsComponentsWrapperDesign2 = "J_jr d_jr d_x d_v d_ct d_b2 d_b4 d_bB";
export var testimonialsComponentsWrapperDesign3 = "J_js d_js d_x d_v d_G d_b4 d_bS d_by d_bH d_bN d_bC";
export var testimonialsBackgroundColor = "J_wh d_d6";
export var colEqualHeight = "J_wj d_by";
export var testimonialsColumnWrapper = "J_jx d_jx d_b2";
export var testimonialsImageWrapper = "J_jm d_jm d_v d_Y";
export var testimonialsImageWrapperDesign2 = "J_jn d_jn d_q d_Y d_bw";
export var testimonialsImageWrapperDesign3 = "J_jp d_jp d_Y d_by";
export var design2TextWrapper = "J_wk d_bB d_bN d_bH";
export var design3 = "J_wl d_cC d_G";
export var imageDesign2 = "J_wm";
export var SmallSmall = "J_sG F_sG F_tZ F_vl";
export var SmallNormal = "J_sH F_sH F_tZ F_vm";
export var SmallLarge = "J_sJ F_sJ F_tZ F_vj";
export var exceptionWeight = "J_tT F_vq";