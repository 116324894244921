// extracted by mini-css-extract-plugin
export var carouselContainer = "P_w8 d_v d_G d_bd d_Y";
export var carouselContainerCards = "P_w9 P_w8 d_v d_G d_bd d_Y";
export var carouselContainerSides = "P_xb d_v d_G d_Y";
export var prevCarouselItem = "P_xc d_v d_G d_Z d_j";
export var prevCarouselItemL = "P_xd P_xc d_v d_G d_Z d_j";
export var moveInFromLeft = "P_xf";
export var prevCarouselItemR = "P_xg P_xc d_v d_G d_Z d_j";
export var moveInFromRight = "P_xh";
export var selectedCarouselItem = "P_xj d_v d_G d_Y d_bd";
export var selectedCarouselItemL = "P_xk P_xj d_v d_G d_Y d_bd";
export var selectedCarouselItemR = "P_xl P_xj d_v d_G d_Y d_bd";
export var nextCarouselItem = "P_xm d_v d_G d_Z d_j";
export var nextCarouselItemL = "P_xn P_xm d_v d_G d_Z d_j";
export var nextCarouselItemR = "P_xp P_xm d_v d_G d_Z d_j";
export var arrowContainer = "P_xq d_G d_Z d_dz d_bk d_3 d_b0";
export var prevArrowContainer = "P_xr P_xq d_G d_Z d_dz d_bk d_3 d_b0";
export var prevArrowContainerHidden = "P_xs P_xr P_xq d_G d_Z d_dz d_bk d_3 d_b0 d_j";
export var nextArrowContainer = "P_xt P_xq d_G d_Z d_dz d_bk d_3 d_b0";
export var carouselContainerProducts = "P_xv";
export var nextArrowContainerHidden = "P_xw P_xt P_xq d_G d_Z d_dz d_bk d_3 d_b0 d_j";
export var arrow = "P_kF d_Z";
export var prevArrow = "P_xx P_kF d_Z";
export var nextArrow = "P_xy P_kF d_Z";
export var carouselIndicatorContainer = "P_xz d_v d_dv d_bk d_by d_bC d_bN d_bH d_Z";
export var btnsContainer = "P_xB d_v d_by d_bD";
export var carouselText = "P_xC d_dp d_by d_bC d_bN d_b0 d_dz d_cC";
export var carouselTextInactive = "P_xD P_xC d_dp d_by d_bC d_bN d_b0 d_dz d_cC";
export var indicator = "P_xF d_b6";
export var carouselIndicator = "P_xG P_xF d_b6";
export var carouselIndicatorSelected = "P_xH P_xF d_b6";
export var arrowsContainerTopRight = "P_xJ d_Z d_bk d_bB";
export var arrowsContainerBottomLeft = "P_xK d_Z d_bk d_bB";
export var arrowsContainerSides = "P_xL d_Z d_bk d_bB";
export var smallArrowsBase = "P_xM d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainer = "P_xN P_xM d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerPrev = "P_xP P_xN P_xM d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerSmall = "P_xQ P_xM d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerPrevSmall = "P_xR P_xQ P_xM d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var icon = "P_nB";
export var iconSmall = "P_xS";
export var multipleWrapper = "P_xT d_bB d_bD d_bd";
export var multipleImage = "P_xV d_bB";
export var sidesPrevContainer = "P_xW P_xN P_xM d_bS d_by d_bC d_bN d_b0 d_dz d_cC d_fm d_Z d_bk d_cC";
export var sidesNextContainer = "P_xX P_xN P_xM d_bS d_by d_bC d_bN d_b0 d_dz d_cC d_fm d_Z d_bk d_cC";