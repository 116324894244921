import React from 'react';
import clsx from 'clsx';

import * as styles from '../styles.module.css';

const AudioElement = ({ item, subIndex, space, isFirst = true }) => {
  return (
    <div
      className={clsx(subIndex == null && `col-10 col-lg-8 ${styles.element}`)}
      style={space ? { marginBottom: `${space}px`, marginTop: `${space}px`, width: '100%' } : undefined}
    >
      {item.content.url.toLowerCase().includes('soundcloud.com') ? (
        <iframe
          width="100%"
          height={item.content.playerHeight}
          scrolling="no"
          frameBorder="no"
          allow="autoplay"
          src={`https://w.soundcloud.com/player/?url=${
            item.content.url
          }&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=${
            item.content.soundcloudPlayerType === 'visual' ? 'true' : 'false'
          }`}
          loading={isFirst ? 'eager' : 'lazy'}
        />
      ) : (
        <audio controls style={{ width: '100%' }}>
          <source src={item.content.url} />
          <p>
            Your browser does not support HTML5 audio. <a href={item.content.url}>Download file</a>
          </p>
        </audio>
      )}
    </div>
  );
};

export default AudioElement;
