// extracted by mini-css-extract-plugin
export var alignDiscLeft = "B_tm d_fn d_bF d_dt";
export var alignLeft = "B_qF d_fn d_bF d_dt";
export var alignDiscCenter = "B_tn d_fp d_bC d_dv";
export var alignCenter = "B_bN d_fp d_bC d_dv";
export var alignDiscRight = "B_tp d_fq d_bG d_dw";
export var alignRight = "B_qG d_fq d_bG d_dw";
export var footerContainer = "B_tq d_dV d_bV";
export var footerContainerFull = "B_tr d_dS d_bV";
export var footerHeader = "B_kc d_kc";
export var footerTextWrapper = "B_ts d_v";
export var footerDisclaimerWrapper = "B_kk d_kk d_cj";
export var badgeWrapper = "B_tt d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "B_tv undefined";
export var footerDisclaimerLeft = "B_tw undefined";
export var verticalTop = "B_tx d_by d_bH d_bM d_bK";
export var firstWide = "B_ty";
export var disclaimer = "B_tz d_by d_bH";
export var socialDisclaimer = "B_tB";
export var left = "B_tC";
export var wide = "B_tD d_cx";
export var right = "B_tF d_bJ";
export var line = "B_fj d_fk d_ct";
export var badgeDisclaimer = "B_tG d_bB d_bN d_bH";
export var badgeContainer = "B_tH d_by d_bG d_bN";
export var badge = "B_tJ";
export var padding = "B_tK d_c6";
export var end = "B_tL d_bG";
export var linkWrapper = "B_tM d_dz";
export var link = "B_mX d_dz";
export var colWrapper = "B_tN d_cw";
export var consent = "B_g d_g d_bB d_bN";
export var media = "B_tP d_bw d_dx";
export var itemRight = "B_tQ";
export var itemLeft = "B_tR";
export var itemCenter = "B_tS";
export var exceptionWeight = "B_tT F_vq";