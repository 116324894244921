// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "z_s3 d_gs d_cr";
export var heroHeaderCenter = "z_gt d_gt d_cr d_dv";
export var heroHeaderRight = "z_gv d_gv d_cr d_dw";
export var heroParagraphLeft = "z_s4 d_gp d_cv";
export var heroParagraphCenter = "z_gq d_gq d_cv d_dv";
export var heroParagraphRight = "z_gr d_gr d_cv d_dw";
export var heroBtnWrapperLeft = "z_s5 d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "z_s6 d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "z_s7 d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "z_s8 d_gn d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "z_s9 d_gm d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "z_tb F_tb";
export var heroExceptionNormal = "z_tc F_tc";
export var heroExceptionLarge = "z_td F_td";
export var Title1Small = "z_tf F_tf F_tZ F_t0";
export var Title1Normal = "z_tg F_tg F_tZ F_t1";
export var Title1Large = "z_th F_th F_tZ F_t2";
export var BodySmall = "z_tj F_tj F_tZ F_vh";
export var BodyNormal = "z_tk F_tk F_tZ F_vj";
export var BodyLarge = "z_tl F_tl F_tZ F_vk";