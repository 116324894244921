// extracted by mini-css-extract-plugin
export var articleWrapper = "q_m0";
export var searchResult = "q_rh q_m0";
export var line = "q_fj d_fj d_cX";
export var articleTitle = "q_rj d_cp";
export var articleText = "q_m1 d_cr";
export var authorImage = "q_lm d_lm d_b6 d_Y d_cs";
export var authorText = "q_ns d_cg d_cs";
export var textCenter = "q_dv d_dv";
export var searchWrapper = "q_rk d_by d_bN d_dq d_ck d_Y";
export var searchInput = "q_rl d_cD d_v d_b3";
export var searchBtn = "q_rm d_b0 d_bB d_bN d_Z d_cC d_dz";
export var text = "q_nD d_cq";
export var elementWrapper = "q_mS d_ck";
export var select = "q_rn d_v d_b3";
export var articleImageWrapper = "q_nj d_cr d_Y d_T";
export var articleImageWrapperIcon = "q_nk d_cr d_Y d_T";
export var articleImageWrapperColumns = "q_rp d_cr d_fg d_Y";
export var contentPadding = "q_rq d_cR";
export var otherPadding = "q_rr d_cL";
export var paginationWrapper = "q_rs";
export var pagination = "q_rt";
export var disabledPagination = "q_rv";
export var breakMe = "q_rw";
export var activePage = "q_rx";
export var next = "q_ry";
export var pages = "q_rz";
export var searchTitle = "q_rB d_cq";
export var categoryTitle = "q_rC d_cq d_Y";
export var searchText = "q_rD d_cs";
export var bold = "q_rF";
export var field = "q_rG";
export var dateTag = "q_m9 d_k d_cq d_dp";
export var icon1 = "q_nb d_cN";
export var icon2 = "q_nc d_cN d_cH";
export var tagBtn = "q_nd d_b0 d_cC d_dn d_dz";
export var linksWrapper = "q_nw d_cg d_dv";
export var header = "q_m2 d_v d_Y";
export var headerImage = "q_rH d_bc d_Z d_7 d_6 d_3 d_8";
export var separator = "q_nr d_cX";
export var resultImage = "q_rJ";