// extracted by mini-css-extract-plugin
export var customText = "v_r5 d_dt d_cr d_cf";
export var videoIframeStyle = "v_nh d_d4 d_v d_G d_bx d_b0 d_Q";
export var customImageWrapper = "v_qJ d_cr d_cf d_Y";
export var customRow = "v_nC d_bC d_bd";
export var quoteWrapper = "v_gN d_bB d_bN d_cr d_cf";
export var quoteBar = "v_nm d_G";
export var masonryImageWrapper = "v_nt";
export var title = "v_r6";
export var Title3Small = "v_r7 F_r7 F_tZ F_t6";
export var Title3Normal = "v_r8 F_r8 F_tZ F_t7";
export var Title3Large = "v_r9 F_r9 F_tZ F_t8";