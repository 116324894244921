// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "G_v0 d_bC";
export var storyRowWrapper = "G_hv d_hv d_bJ";
export var storyLeftWrapper = "G_v1 d_by d_bN";
export var storyWrapperFull = "G_v2 d_cC";
export var storyWrapperFullLeft = "G_p9 d_cC d_by d_bN d_bC";
export var contentWrapper = "G_m8 d_hw";
export var storyLeftWrapperCenter = "G_v3 d_hx d_by d_bC d_bN d_bH";
export var storyRightWrapperCenter = "G_v4 d_hC";
export var storyHeader = "G_v5 d_hB d_v d_cr";
export var storyHeaderCenter = "G_hz d_hz d_v d_cr d_dv d_bx";
export var storyParagraphCenter = "G_hy d_hy d_bx d_dv";
export var storyBtnWrapper = "G_v6 d_d1 d_d0 d_v d_by d_bF";
export var storyBtnWrapperCenter = "G_hG d_hG d_d0 d_v d_by d_bC";
export var imageWrapper = "G_qK d_ff d_Y";
export var imageWrapperFull = "G_sy d_v d_G d_bd d_Y";