function getUsedFonts(typography, nameActive, logo) {
  // { 'Open sans': [300, 400], ... }
  const fonts = {};

  Object.keys(typography).forEach((key) => {
    const font = typography[key];

    if (font?.name && (key !== 'logo' || (key === 'logo' && (nameActive || (nameActive == null && !logo?.active))))) {
      // strip fallbacks
      const fontName = font.name.split(',')[0];

      if (!fonts[fontName]) {
        fonts[fontName] = [];
      }

      if (!fonts[fontName].includes(font.weight)) {
        fonts[fontName].push(font.weight);
      }
    }
  });

  return fonts;
}

module.exports = getUsedFonts;
