// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "w_sb d_bC";
export var datasheetRowWrapper = "w_ls d_ls d_bJ";
export var datasheetLeftWrapper = "w_sc d_by d_bN";
export var datasheetWrapperFull = "w_sd d_cC";
export var datasheetWrapperFullLeft = "w_p4 d_cC d_by d_bN d_bC d_bS";
export var contentWrapper = "w_m8 d_lt";
export var contentWrapperCenter = "w_sf d_ly";
export var datasheetLeftWrapperCenter = "w_sg d_lw d_by d_bC d_bN d_bH";
export var datasheetImageCenterWrapper = "w_lq d_lq d_v d_ct";
export var datasheetRightWrapperCenter = "w_sh d_lv";
export var datasheetFooter = "w_lx d_lx";
export var alignLeft = "w_qF d_dt";
export var alignCenter = "w_bN d_dv";
export var alignRight = "w_qG d_dw";
export var datasheetInnerWrapperNewLeft = "w_sj d_fd d_N d_Y d_by d_bN d_dh";
export var datasheetInnerWrapperLeft = "w_sk d_fc d_N d_Y d_by d_bN d_b4 d_dh d_c8";
export var datasheetInnerWrapperRight = "w_sl d_fd d_N d_Y d_by d_bN d_dh";
export var datasheetRowWrapperReverse = "w_sm d_bJ";
export var datasheetSubtitle = "w_sn d_cw";
export var tableRow = "w_sp";
export var cell = "w_sq";
export var tableRowWrapper = "w_sr d_v d_dt";
export var tableHeadingCell = "w_ss";
export var tableHeading = "w_st";
export var tableRowStriped = "w_sv";
export var tableRowHeading = "w_sw";
export var dataTable = "w_sx d_v";
export var imageWrapper = "w_qK d_ff d_Y";
export var imageWrapperFull = "w_sy d_G d_v d_bd d_Y";
export var imageWrapperIcon = "w_sz";
export var titleMargin = "w_sB d_cr";
export var datasheetInnerInnerWrapper = "w_sC d_v";
export var hasLabels = "w_sD";
export var label = "w_sF";
export var SmallSmall = "w_sG F_sG F_tZ F_vl";
export var SmallNormal = "w_sH F_sH F_tZ F_vm";
export var SmallLarge = "w_sJ F_sJ F_tZ F_vj";