// extracted by mini-css-extract-plugin
export var articleWrapper = "j_m0";
export var articleText = "j_m1 d_dt d_cr";
export var header = "j_m2 d_Y d_v";
export var headerImageWrapper = "j_m3 d_bc d_Z d_7 d_6 d_3 d_8 d_bj d_bQ";
export var headerGradient = "j_m4 d_bc d_Z d_7 d_6 d_3 d_8";
export var headerGradientOverlay = "j_m5 d_bc d_Z d_7 d_6 d_3 d_8";
export var headerContentWrapper = "j_m6 d_by d_bN d_bC d_bH d_bk d_Y";
export var headerContentWrapperAlt = "j_m7 d_by d_bN d_bC d_bH d_bk d_Y";
export var contentWrapper = "j_m8 d_v";
export var dateTag = "j_m9 d_cd d_cq d_dp d_v";
export var icon1 = "j_nb d_cN";
export var icon2 = "j_nc d_cN d_cH";
export var tagBtn = "j_nd d_b0 d_cC d_dn d_dz";
export var headerText = "j_nf d_v d_cd d_cr d_bV";
export var center = "j_ng d_dv";
export var videoIframeStyle = "j_nh d_d4 d_v d_G d_bx d_b0 d_Q";
export var articleImageWrapper = "j_nj d_cr d_Y";
export var articleImageWrapperIcon = "j_nk d_cr d_Y";
export var articleRow = "j_nl d_bC";
export var quoteWrapper = "j_gN d_bB d_bN d_cr";
export var quoteBar = "j_nm d_G";
export var quoteText = "j_nn";
export var authorBox = "j_np d_v";
export var authorRow = "j_nq d_bC d_bV d_cx";
export var separator = "j_nr d_v";
export var line = "j_fj d_v d_fj d_cX d_cx";
export var authorImage = "j_lm d_b6 d_Y d_v d_cs";
export var authorText = "j_ns d_cg d_cs";
export var masonryImageWrapper = "j_nt";
export var bottomSeparator = "j_nv d_v d_cx";
export var linksWrapper = "j_nw d_cg d_dt";
export var comments = "j_nx d_bC d_cx";
export var sharing = "j_ny d_by d_bC d_bH";
export var shareText = "j_nz d_v d_dv";
export var icon = "j_nB";
export var customRow = "j_nC d_bC d_Y";
export var text = "j_nD";
export var SubtitleSmall = "j_nF";
export var SubtitleNormal = "j_nG";
export var SubtitleLarge = "j_nH";