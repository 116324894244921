// extracted by mini-css-extract-plugin
export var warningBackgroundlight = "g_mM";
export var warningBackgrounddark = "g_mN";
export var warningBackgroundcustom = "g_mP";
export var bottom = "g_gF";
export var modal = "g_mQ";
export var container = "g_mR";
export var btnWrapper = "g_d1 d_bC d_bN d_bH";
export var elementWrapper = "g_mS d_bC d_bN d_dv";
export var titleWrapper = "g_mT d_bC d_bN";
export var cookieButtons = "g_mV d_by d_bC d_bN d_bH";
export var btn = "g_mW d_by d_bC d_bN";
export var link = "g_mX";
export var decline = "g_mY g_mW d_by d_bC d_bN";
export var row = "g_mZ";